'use client';
import type { MenuItemProps } from 'components/MenuItem/DesktopMenuItem';
import type { ReactElement } from 'react';

import { useEffect, useState } from 'react';
import Image from 'next/image';
import { Disclosure } from '@headlessui/react';

import { Arrow } from 'assets/icons';
import useViewport from 'hooks/useViewport';
import { Separator } from 'components/Separator';
import { FooterMenuItem } from 'components/FooterMenuItem';
import { FooterLink } from 'components/FooterLink';

interface Link {
  label: string;
  path: string;
}

interface SubCategory {
  label: string;
  subMenu: Link[];
}

interface FooterMenuOption {
  label: string;
  innerOptions: boolean;
  options: {
    label: string;
    href?: string;
    links?: Link[];
  }[];
}

interface FooterMenuComponentsProps {
  label: string;
  footerOptions?: ReactElement[];
}

interface FooterMenuProps {
  conditionsLinks: MenuItemProps[];
}

function FooterMenuDisclosure({ label, footerOptions }: FooterMenuComponentsProps) {
  return (
    footerOptions &&
    footerOptions.length > 0 && (
      <Disclosure as="div" className="flex flex-col py-8">
        {({ open }) => (
          <>
            <Disclosure.Button className="flex items-center justify-between font-semibold text-teal-900">
              {label}
              <Image alt="" className={open ? 'rotate-180' : 'rotate-0'} src={Arrow} />
            </Disclosure.Button>
            <Disclosure.Panel as="div" className="flex flex-col gap-y-4 pt-4">
              {footerOptions}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    )
  );
}

function FooterMenuColumn({ label, footerOptions }: FooterMenuComponentsProps) {
  return (
    footerOptions &&
    footerOptions.length > 0 && (
      <div className="w-40">
        <span className="text-sm font-semibold text-teal-900">{label}</span>
        <div className="flex flex-col gap-y-4 pt-4">{footerOptions}</div>
      </div>
    )
  );
}

export default function FooterMenu({ conditionsLinks }: FooterMenuProps) {
  const { isMobile, isTablet, isDesktop } = useViewport();
  const [footerMenuOptions, setFooterMenuOptions] = useState<FooterMenuOption[]>([]);

  useEffect(() => {
    const fetchData = () => {
      const mappedMenuOptions: FooterMenuOption[] = conditionsLinks.map(({ label, subMenu }) => {
        if (label === 'Conditions') {
          return {
            label,
            innerOptions: true,
            options: (subMenu as SubCategory[]).map((category) => ({
              label: category.label,
              links: category.subMenu.map((link) => ({
                label: link.label,
                path: link.path,
              })),
            })),
          };
        }

        return {
          label,
          innerOptions: false,
          options: (subMenu as Link[]).map(({ label, path }) => ({
            label,
            path,
          })),
        };
      });

      setFooterMenuOptions(mappedMenuOptions);
    };

    fetchData();
  }, [conditionsLinks]);

  return (
    <>
      {footerMenuOptions.map(({ label, innerOptions, options }) => {
        const footerOptions = innerOptions
          ? options.map((option, index) => (
              <FooterMenuItem key={index} category={option.label} links={option.links || []} />
            ))
          : options?.map((option, index) => <FooterLink key={index} link={option} />);

        return footerMenuOptions.length > 0 && (isMobile || isTablet || isDesktop) ? (
          <div key={label}>
            <FooterMenuDisclosure footerOptions={footerOptions} label={label} />
            <Separator />
          </div>
        ) : (
          <FooterMenuColumn key={label} footerOptions={footerOptions} label={label} />
        );
      })}
    </>
  );
}
