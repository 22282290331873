'use client';

import Link from 'next/link';
import { type FC } from 'react';

import { type SubMenuItemProps } from 'components/MenuItem/DesktopMenuItem';
import NestedSubMenu from 'components/NestedSubMenu/NestedSubMenu';

import { useSubMenuState } from './hooks/useSubMenu';

export interface SubMenuProps {
  subMenu?: SubMenuItemProps[];
}

const SubMenu: FC<SubMenuProps> = ({ subMenu }) => {
  const { hasNestedSubmenu, nestedSubmenu, activeSubmenu, dispatch } = useSubMenuState({ subMenu });

  if (subMenu?.length === 0) return null;

  const handleMouseOver = (submenu: SubMenuItemProps[], title: string) => {
    dispatch({
      nestedSubmenu: { submenu, title },
      activeSubmenu: title,
    });
  };

  return (
    <div
      className="top-[100%] z-10 flex h-fit max-h-[600px] bg-white shadow-tooltipWrapper"
      style={{ width: nestedSubmenu.submenu?.length > 8 ? 'auto' : 'fit-content' }}
    >
      <ul className="flex-col py-2" style={{ width: hasNestedSubmenu ? '9.5rem' : '12.5rem' }}>
        {subMenu?.map(({ noLink = false, label, subMenu, path }, index) => {
          const isActive = activeSubmenu === label;
          const submenuClassName = `group/subMenuItem relative cursor-pointer hover:bg-arctic-600 ${
            isActive ? 'bg-arctic-600' : ''
          }`;
          const labelClassName = `block w-full px-5 py-2 text-xs text-black-900 group-hover/subMenuItem:font-semibold group-hover/subMenuItem:text-teal-900 ${
            isActive ? 'font-semibold text-teal-900' : ''
          }`;

          return (
            <li
              key={index}
              className={submenuClassName}
              onMouseOver={() => handleMouseOver(subMenu ?? [], label)}
            >
              {noLink ? (
                <span className={labelClassName}>{label} &gt;</span>
              ) : (
                <Link
                  aria-disabled={noLink}
                  className={labelClassName}
                  href={`${path ? `/${path}` : ''}`}
                  scroll={false}
                >
                  {label} &gt;
                </Link>
              )}

              {hasNestedSubmenu && (
                <span
                  className={`border-left group-hover/subMenuItem:visible ${
                    isActive ? 'visible' : 'invisible'
                  } absolute left-[100%] top-0 h-[100%] w-[0.5rem] border-y-[1rem] border-l-[0.8rem] border-solid border-y-transparent border-l-arctic-600`}
                />
              )}
            </li>
          );
        })}
      </ul>
      {hasNestedSubmenu && (
        <NestedSubMenu
          hasNestedSubmenu={hasNestedSubmenu}
          nestedSubmenu={nestedSubmenu}
          setActiveSubmenu={(title: string) => {
            dispatch({ activeSubmenu: title });
          }}
        />
      )}
    </div>
  );
};

export default SubMenu;
