import { type FC, Fragment, useState, useContext } from 'react';
import Image from 'next/image';
import { Transition } from '@headlessui/react';

import { type MenuItemProps } from 'components/MenuItem/DesktopMenuItem';
import { HeaderContext } from 'components/Header/context/headerContext';
import MobileMenuItem from 'components/MobileMenuItem/MobileMenuItem';
import { Close } from 'assets/icons';

interface MobileMenuProps {
  menuHeaderLinks: MenuItemProps[];
}

const MobileMenu: FC<MobileMenuProps> = ({ menuHeaderLinks }) => {
  const { showMenu, handleHideMenu, handleShowMenu } = useContext(HeaderContext);
  const [showGoBack, setShowGoBack] = useState<boolean>(false);

  return (
    <Transition
      as={Fragment}
      enter="transition ease-out duration-200"
      enterFrom="opacity-0 translate-y-1"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease-in duration-150"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 translate-y-1"
      show={showMenu}
    >
      <div className="fixed left-[0] top-[68px] z-[999] max-h-[93vh] w-screen overflow-y-auto bg-white shadow-xl md:hidden">
        <div className="flex justify-between px-5 py-3">
          {showGoBack ? (
            <label
              className="text-base text-black-400"
              onClick={() => {
                setShowGoBack(false);
              }}
            >
              Back
            </label>
          ) : (
            <label className="text-base text-black-400">Menu</label>
          )}
          <Image alt="Close Menu" src={Close} onClick={handleHideMenu} />
        </div>
        <ul className="flex flex-col">
          {menuHeaderLinks.map(
            (menuLink) =>
              menuLink.subMenu.length > 0 && (
                <li key={menuLink.path} className="py-3">
                  <MobileMenuItem
                    setShowGoBack={setShowGoBack}
                    setShowMenu={handleShowMenu}
                    showGoBack={showGoBack}
                    {...menuLink}
                  />
                </li>
              ),
          )}
        </ul>
      </div>
    </Transition>
  );
};

export default MobileMenu;
