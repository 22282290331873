'use client';
import Link from 'next/link';
import { useContext, type FC } from 'react';

import { type SubMenuItemProps } from 'components/MenuItem/DesktopMenuItem';
import { HeaderContext } from 'components/Header/context/headerContext';

export interface NestedSubmenu {
  title: string;
  submenu: SubMenuItemProps[];
}

export interface NestedSubMenuProps {
  hasNestedSubmenu: boolean;
  nestedSubmenu: NestedSubmenu;
  setActiveSubmenu: (title: string) => void;
}

const NestedSubMenu: FC<NestedSubMenuProps> = ({ nestedSubmenu, setActiveSubmenu }) => {
  const { handleHideSubMenu } = useContext(HeaderContext);

  return (
    <div
      className="group/subMenuItemLinks inline-flex grow flex-col bg-arctic-300 px-8 py-4"
      onMouseOver={() => {
        setActiveSubmenu(nestedSubmenu.title);
      }}
    >
      <span className="text-xs text-black-500">{nestedSubmenu.title}</span>
      <ul className="mt-2 flex h-fit max-h-[510px] flex-col flex-nowrap overflow-y-scroll scrollbar-hide lg:flex-wrap">
        {nestedSubmenu.submenu.map(({ label, path }) => (
          <li
            key={path}
            className="group/subMenuItemLink max-w-[150px] cursor-pointer py-2 hover:font-semibold hover:text-teal-900 md:max-w-[250px]"
            style={{ flexBasis: 'fit-content' }}
          >
            <Link
              className="text-xs text-black-800"
              href={`${path ? `/${path}` : ''}`}
              onClick={() => {
                handleHideSubMenu();
              }}
            >
              <span className="group-hover/subMenuItemLink:underline">{label}</span>
              <span> &gt;</span>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default NestedSubMenu;
